import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faUser } from '@fortawesome/free-solid-svg-icons';
import { RiMessage2Fill } from 'react-icons/ri';
import { IoMdArrowDropdown } from 'react-icons/io';
import SearchForm from '../SearchForm';
import Logout from '../Login/Logout';
import Hamburger2 from '../Hamburgers/Hamburger2';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import { Link } from 'react-router-dom';

function Navigations() {
  const [notification, setNotification] = useState(0);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState([]);

  const user_id = localStorage.getItem('user_id');
  const estateId = localStorage.getItem('estate_id');
  const ireport_id = localStorage.getItem('ireport_id');
  console.log(ireport_id);


  const fetchResponses = async () => {
    try {
      const url = `${BASE_URL}/responses/report/${ireport_id}`;
      console.log('Request URL:', url);

      const response = await axios.get(url);
      console.log('Response Data:', response.data);


      setResponses(response.data.data);

      const unreadResponses = response.data.data.filter(
        (response) => !response.response_is_read
      );

      console.log('Unread Responses:', unreadResponses);
      setNotification(unreadResponses.length);

      setError(null);
    } catch (error) {
      console.error('Error fetching responses:', error);
      setError(
        error.response?.data?.message ||
        'An error occurred while fetching responses'
      );
      setNotification(0);
    }
  };



  const markResponsesAsRead = async (responseIds) => {
    try {

      for (let id of responseIds) {
        const url = `${BASE_URL}/responses/${id}/read`;
        await axios.put(url);
      }

      // Re-fetch the responses to update the notification count
      fetchResponses();
    } catch (error) {
      console.error('Error marking responses as read:', error);
    }
  };

  const handleNotificationClick = () => {
    const unreadResponseIds = responses
      .filter((response) => !response.response_is_read)
      .map((response) => response._id);

    if (unreadResponseIds.length > 0) {
      markResponsesAsRead(unreadResponseIds);
    }
  };



  useEffect(() => {
    if (localStorage.getItem('role') === 'USER' && user_id && estateId) {
      fetchResponses();
    }
  }, [user_id, estateId]);

  return (
    <div>
      {user_id ? (
        <div className='navi-gation'>
          <div
            className='nav_gation'
            style={{ width: '97%', margin: 'auto', paddingTop: '10px' }}
          >
            <div
              className='navigation_items d-flex'
              style={{ justifyContent: 'space-between' }}
            >
              <div className='search_barr'>
                <div className='ham'>
                  <Hamburger2 />
                </div>
                <form className='example'>
                  <SearchForm />
                </form>
              </div>
              <div className='not-fications d-flex gap-1'>
                <ul className='btn-group'>
                  <div className='user-msg'>
                    <Link
                      to={`/Notifications/${user_id}`}
                      state={{ responses }}
                      onClick={handleNotificationClick}
                    >
                      <button className='user-msg-btn'>
                        <RiMessage2Fill />
                        {notification > 0 && (
                          <span
                            className='notification--count'
                            style={{
                              position: 'absolute',
                              top: '0px',
                              left: '42px',
                              backgroundColor: 'red',
                              borderRadius: '32px',
                              color: 'white',
                              fontWeight: '700',
                              padding: '0px 6px',
                              fontSize: '13px',
                            }}
                          >
                            {notification}
                          </span>
                        )}
                      </button>
                    </Link>
                  </div>
                  <button
                    className='dropdown-btn btn-sm'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ width: '35%', fontSize: '24px' }}
                    />
                    <IoMdArrowDropdown />
                  </button>
                  <ul className='dropdown-menu'>
                    <div
                      className='user___details'
                      style={{ width: '95%', margin: 'auto' }}
                    >
                      {localStorage.getItem('firstname') && (
                        <li>{localStorage.getItem('firstname')}</li>
                      )}
                      <br />
                      <div className='nav_settings d-flex gap-2'>
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{ marginTop: '4px' }}
                        />
                        <div>
                          <button
                            className='settings_btn'
                            style={{
                              border: 'none',
                              background: 'transparent',
                              color: 'black',
                              fontWeight: '600',
                            }}
                          >
                            Settings
                          </button>
                        </div>
                      </div>
                      <br />
                      <Logout />
                    </div>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );

}

export default Navigations;
