import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import Navigation from './Navigation';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function Allnotifications() {
  const [notifications, setNotifications] = useState([]);
  const [editingNotification, setEditingNotification] = useState(null);
  const estate_id = localStorage.getItem('estate_id');

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/notify/estate/${estate_id}`
        );
        // console.log('API Response:', response.data);
        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [estate_id]);

  const handleEdit = (notification) => {
    setEditingNotification(notification);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingNotification((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/notify/${editingNotification._id}`,
        editingNotification
      );
      const updatedNotification = response.data;
      const updatedNotifications = notifications.map((notification) =>
        notification._id === updatedNotification._id
          ? updatedNotification
          : notification
      );
      setNotifications(updatedNotifications);
      setEditingNotification(null);
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN';
  let isLoggedIn = Boolean(is_logged_in);

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='all-notifications d-flex'>
          <div className='artisan_left'>
            <Compo />
          </div>

          <div className='users_'>
            <Navigation />
            <br />

            <div className='notification_content'>
              <h3>All Notifications</h3>

              {notifications.map((notification, index) => (
                <div key={index} className='notification-bcolor'>
                  {editingNotification &&
                  editingNotification._id === notification._id ? (
                    <form className='txt-area' onSubmit={handleSubmit}>
                      <input
                        type='text'
                        name='header'
                        value={editingNotification.header || ''}
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='sub_header'
                        value={editingNotification.sub_header || ''}
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='date'
                        value={editingNotification.date || ''}
                        onChange={handleInputChange}
                      />
                      <input
                        type='text'
                        name='info_head'
                        value={editingNotification.info_head || ''}
                        onChange={handleInputChange}
                      />

                      <textarea
                        type='text'
                        name='info'
                        value={editingNotification.info || ''}
                        onChange={handleInputChange}
                      />
                      <button className='notification-edit-btn' type='submit'>
                        Update
                      </button>
                    </form>
                  ) : (
                    <div>
                      <h4>{notification.header}</h4>
                      <p>{notification.sub_header}</p>
                      <p>{notification.date}</p>
                      <p>{notification.info_head}</p>
                      <div className='notification-info'>
                        <p>{notification.info}</p>
                      </div>
                      <button
                        className='notification-edit-btn'
                        onClick={() => handleEdit(notification)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <br />
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default Allnotifications;
