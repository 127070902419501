import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes'; 
import AuthProvider from './component/Login/AuthContext';
import { ReportProvider } from './component/Reports/ReportContext';
import { ReplyProvider } from './component/Reports/ReplyContext';

// Component imports
import Panel from './component/Others/Panel';
import Compo from './component/Others/Compo';
import Dark from './component/Others/Dark';
import ResidentForm from './component/Residence/ResidentForm';
import AllResidents from './component/Residence/AllResidents';
import AddPayments from './component/Payments/AddPayments';
import PaymentsInfo from './component/Payments/PaymentsInfo';
import PaymentCompliance from './component/Payments/PaymentCompliance';
import ViewReports from './component/Reports/ViewReports';
import PendingiReports from './component/Reports/PendingiReports';
import Navigation from './component/Others/Navigation';
import Login from './component/Login/Login';
import SingleResidentProfile from './component/Residence/SingleResidentProfile';
import Logout from './component/Login/Logout';
import ForgotPassword from './component/Login/ForgotPassword';
import GenerateReport from './component/Reports/GenerateReport';
import ReportDetails from './component/Reports/ReportDetails';
import Allnotifications from './component/Others/Allnotifications';
import SearchForm from './component/SearchForm';
import SearchResults from './component/SearchResults';
import LineChart from './component/Others/LineChart';
import Emergency from './component/Emergency/Emergency';
import AllEmergency from './component/Emergency/AllEmergency';
import Workers from './component/Employees/Workers.js'
import WorkersList from './component/Employees/WorkersList';
import UserEmergency from './component/Users/UserEmergency';
import Hamburger from './component/Hamburgers/Hamburger';
import Hamburger2 from './component/Hamburgers/Hamburger2';
import Hamburger3 from './component/Hamburgers/Hamburger3';
import PaymentsDetails from './component/Payments/PaymentsDetails';
import Footer from './component/Others/Footer';
import Config from './component/Others/Config';
import ViewAllArtisan from './component/Artisan/ViewAllArtisan';
import AddArtisan from './component/Artisan/AddArtisan';
import Artisans from './component/Users/Artisans';
import Dashboard from './component/Users/Dashboard';
import Left from './component/Users/Left';
import SendReport from './component/Users/SendReport';
import Navigations from './component/Users/Navigations';
import ReportHistory from './component/Users/ReportHistory';
import SuperAdminPage from './component/SuperAdmin/SuperAdminPage';
import AdminSideBar from './component/SuperAdmin/AdminSideBar';
import UpdateModal from './component/SuperAdmin/UpdateModal';
import AdminDashboard from './component/SuperAdmin/AdminDashboard';
import CreateEstate from './component/SuperAdmin/CreateEstate';
import Navigationz from './component/SuperAdmin/Navigationz';
import Landingpage from './component/Profile/Landingpage';
import Settings from './component/Setting/Settings';
import Qrscanner from './component/Qrscanner';
import Household from './component/Users/Household';
import Paymentshistory from './component/Users/Paymentshistory';
import Activebutton from './component/Profile/Activebutton';
import InactiveButton from './component/Profile/InactiveButton';
import PasswordPage from './component/Login/PasswordPage';
import Notifications from './component/Others/Notifications';
import Archives from './component/Others/Archives';
import GenerateCustom from './component/Residence/GenerateCustom';
import UserNotification from './component/Users/userNotification';


function App() {
  return (
    <ReplyProvider>
      <ReportProvider>
        <AuthProvider>
          <div className='App'>
            <Router>
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/panel' element={<Panel />} />
                  <Route path='/settings' element={<Settings />} />
                  <Route path='/scanner' element={<Qrscanner />} />
                  <Route path='/landingpage/:usersId' element={<Landingpage />} />
                  <Route path='/Compo' element={<Compo />} />
                  <Route path='/residence/:usersId/:identificationCode' element={<SingleResidentProfile />} />
                  <Route path='/addpayments' element={<AddPayments />} />
                  <Route path='/Notifications/:user_id' element={<UserNotification />} />
                  <Route path='/dark' element={<Dark />} />
                  <Route path='/compliance' element={<PaymentCompliance />} />
                  <Route path='/paymentsInfo' element={<PaymentsInfo />} />
                  <Route path='/navigation' element={<Navigation />} />
                  <Route path='/viewReports' element={<ViewReports />} />
                  <Route path='/pending' element={<PendingiReports />} />
                  <Route path='/ResidentForm' element={<ResidentForm />} />
                  <Route path='/allResidents' element={<AllResidents />} />
                  <Route path='/custom' element={<GenerateCustom />} />
                  <Route path='/generate' element={<GenerateReport />} />
                  <Route path='/logout' element={<Logout />} />
                  <Route path='/ReportDetails/:ireportId' element={<ReportDetails />} />
                  <Route path='/searchform' element={<SearchForm />} />
                  <Route path='/searchresult' element={<SearchResults />} />
                  <Route path='/linechart' element={<LineChart />} />
                  <Route path='/emergency' element={<Emergency />} />
                  <Route path='/allemergency' element={<AllEmergency />} />
                  <Route path='/workers' element={<Workers />} />
                  <Route path='/workerslist' element={<WorkersList />} />
                  <Route path='/helpline' element={<UserEmergency />} />
                  <Route path='/hamburger' element={<Hamburger />} />
                  <Route path='/ham' element={<Hamburger2 />} />
                  <Route path='/hamm' element={<Hamburger3 />} />
                  <Route path='/Payments/:paymentsId' element={<PaymentsDetails />} />
                  <Route path='/footer' element={<Footer />} />
                  <Route path='/config' element={<Config />} />
                  <Route path='/addartisan' element={<AddArtisan />} />
                  <Route path='/viewall' element={<ViewAllArtisan />} />
                  <Route path='/artisans' element={<Artisans />} />
                  <Route path='/left' element={<Left />} />
                  <Route path='/ireport' element={<SendReport />} />
                  <Route path='/navigations' element={<Navigations />} />
                  <Route path='/history' element={<ReportHistory />} />
                  <Route path='/superAdmin' element={<SuperAdminPage />} />
                  <Route path='/adminsidebar' element={<AdminSideBar />} />
                  <Route path='/adminboard' element={<AdminDashboard />} />
                  <Route path='/createEstate' element={<CreateEstate />} />
                  <Route path='/superNav' element={<Navigationz />} />
                  <Route path='/household' element={<Household />} />
                  <Route path='/histories' element={<Paymentshistory />} />
                  <Route path='/active' element={<Activebutton />} />
                  <Route path='/inactive' element={<InactiveButton />} />
                  <Route path='/notify' element={<Notifications />} />
                  <Route path='/archives' element={<Archives />} />
                  <Route path='/reset-password' element={<PasswordPage />} />
                  <Route path='/interface' element={<UpdateModal />} />
                  <Route path='/allnotifications' element={<Allnotifications />} />
                  
                </Route>
                <Route path='/' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Routes>
            </Router>
          </div>
        </AuthProvider>
      </ReportProvider>
    </ReplyProvider>
  );
}

export default App;
