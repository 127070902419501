import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function WorkerList() {
  const estate_id = localStorage.getItem('estate_id');
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [editData, setEditData] = useState({
    fullName: '',
    phoneNo: '',
    permanentAddress: '',
    designation: '',
  });
  const [cardCollected, setCardCollected] = useState(() => {
    const savedState = localStorage.getItem('cardCollected');
    return savedState ? JSON.parse(savedState) : {};
  });

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/workers/estate/${estate_id}`
        );
        const workersByEstate = response.data || [];
        setWorkers(workersByEstate);
      } catch (error) {
        console.error('Error fetching employees:', error);
        // toast.error('Failed to fetch employees. Please try again later.', {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [estate_id]);

  useEffect(() => {
    localStorage.setItem('cardCollected', JSON.stringify(cardCollected));
  }, [cardCollected]);

  const handleUpdateImage = async (workers_id) => {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);

      const response = await axios.put(
        `${BASE_URL}/updatephoto/${workers_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const toggleEditMode = (index) => {
    setEditMode(index);
    const worker = workers[index];
    setEditData({
      fullName: worker.fullName,
      phoneNo: worker.phoneNo,
      permanentAddress: worker.permanentAddress,
      designation: worker.designation,
    });

    setAvatar(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setAvatar(event.target.files[0]);
  };

  const handleSubmit = async (index) => {
    try {
      const workerId = workers[index]._id;
      if (avatar) {
        await handleUpdateImage(workerId);
      }
      const response = await axios.put(
        `${BASE_URL}/workers/${workerId}`,
        editData
      );

      const updatedWorker = response.data;

      const updatedWorkers = [...workers];
      updatedWorkers[index] = updatedWorker;
      setWorkers(updatedWorkers);

      toast.success('Worker updated successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });

      setEditMode(null);
    } catch (error) {
      console.error('Error updating worker:', error);
      toast.error('Failed to update worker. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCardCollectedChange = async (workerId) => {
    try {
      const updatedCardCollectedStatus = !cardCollected[workerId];
      console.log('Updated Card Collected Status:', updatedCardCollectedStatus);

      const response = await axios.put(
        `${BASE_URL}/workers/${workerId}/cardCollected`,
        {
          cardCollected: updatedCardCollectedStatus,
        }
      );

      const updatedWorker = response.data;
      console.log('Updated Worker:', updatedWorker);

      setCardCollected((prevState) => {
        const newState = {
          ...prevState,
          [workerId]: updatedCardCollectedStatus,
        };
        console.log('New Card Collected State:', newState);
        return newState;
      });

      
      setWorkers((prevWorkers) =>
        prevWorkers.map((worker) =>
          worker._id === workerId
            ? { ...worker, cardCollected: updatedCardCollectedStatus }
            : worker
        )
      );
    } catch (error) {
      console.error('Error updating card collected status:', error);
      toast.error(
        'Failed to update card collected status. Please try again later.',
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const isAdmin = localStorage.getItem('role') === 'ADMIN';
  const isLoggedIn = !!localStorage.getItem('user_id');

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='employee d-flex'>
          <div className='artisan_left'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div className='all-employees'>
              <h5>All WORKERS</h5>
              <div className='all-employees-details'>
                {loading && <p>Loading...</p>}
                {workers.map((worker, index) => (
                  <div key={index} className='em-one'>
                    <div className='em-one-color'>
                      <div className='em-image'>
                        <img
                          src={worker.avatar || 'default-avatar.jpg'}
                          alt='Worker Avatar'
                        />
                      </div>
                      <br />
                      {editMode === index ? (
                        <div className='workers--details'>
                          <div className='workers-profile'>
                            <input
                              type='text'
                              name='fullName'
                              value={editData.fullName}
                              onChange={handleInputChange}
                            />
                            <input
                              type='text'
                              name='phoneNo'
                              value={editData.phoneNo}
                              onChange={handleInputChange}
                            />
                            <input
                              type='text'
                              name='permanentAddress'
                              value={editData.permanentAddress}
                              onChange={handleInputChange}
                            />
                            <input
                              type='text'
                              name='designation'
                              value={editData.designation}
                              onChange={handleInputChange}
                            />
                            <input type='file' onChange={handleFileChange} />
                          </div>
                          <button
                            onClick={() => handleSubmit(index)}
                            className='notification-edit-btn'
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        <div className='workers--details'>
                          <div className='workers-profile'>
                            <h4>{worker.fullName}</h4>
                            <p>Phone: {worker.phoneNo}</p>
                            <p>Permanent Address: {worker.permanentAddress}</p>
                            <p>Designation: {worker.designation}</p>
                            <p>Nationality: {worker.nationality}</p>
                            <p>Gender: {worker.gender}</p>
                            <p>
                              Address:{' '}
                              {worker.house_id
                                ? `${worker.house_id.house_number}, ${worker.house_id.house_address}`
                                : '-'}
                            </p>
                          </div>
                          <div className='check_box d-flex'>
                            <button
                              onClick={() => toggleEditMode(index)}
                              className='notification-edit-btn'
                            >
                              EDIT
                            </button>
                            <div className='workers-box'>
                              <label>
                                <input
                                  type='checkbox'
                                  className='large-checkbox'
                                  checked={!!cardCollected[worker._id]}
                                  onChange={() =>
                                    handleCardCollectedChange(worker._id)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {!loading && workers.length === 0 && <p>No employees found.</p>}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have permission to view this page</>
      )}
      
    </div>
  );
}

export default WorkerList;
