import React, { useState, useEffect } from 'react';
import Navigation from '../Others/Navigation';
import Compo  from '../Others/Compo'
import { IoMdArrowDropdown } from 'react-icons/io';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../utils/globals';
import QRCode from 'react-qr-code';

const SingleResidentProfile = () => {
  const [residence, setResidence] = useState(null);
  const [residentId, setResidentId] = useState('');
  const { usersId } = useParams();
  let user_id = usersId;
  const [inputDate, setInputDate] = useState('');
  const [identificationCode, setIdentificationCode] = useState('');
  const [identificationCodes, setIdentificationCodes] = useState({});

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    fetchResidenceData();
  }, []);

  const fetchResidenceData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/users/${usersId}`);
      console.log(response);

      const identificationCode = response.data.identificationCode || '';

      setResidence(response.data);
      setIdentificationCode(identificationCode);
      setResidentId(response.data.residence_id || '');
    } catch (error) {
      console.error(error);
    }
  };

  const updateIdentificationCodes = (code) => {
    setIdentificationCodes((prevCodes) => {
      const nextIndex = Object.keys(prevCodes).length + 1;
      return { ...prevCodes, [code]: String(nextIndex).padStart(3, '0') };
    });
  };

  console.log(user_id);
  console.log('Residence:', residence);

  useEffect(() => {
    if (residence && residence.registration_date) {
      if (residence.created === '') {
        setInputDate('No creation date available');
      } else {
        const createdDate = new Date(residence.registration_date);
        if (!isNaN(createdDate.getTime())) {
          const formattedDate = createdDate.toLocaleDateString();
          setInputDate(formattedDate);
        } else {
          setInputDate('Invalid Date');
        }
      }
    }
  }, [residence]);

  if (!residence) {
    return <div>Loading...</div>;
  }

  const formatIdentificationCode = (code) => {
    const numDigits = 3;
    return code.toString().padStart(numDigits, '0');
  };

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='profile_ d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div className='residents-form'>
              <h5>RESIDENCE PROFILE</h5>
              {/* <Link to={`/landingpage/${usersId}`}>
                <div>
                  <strong>View</strong>
                </div>
              </Link> */}
              <br /> 
              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <div className='search--icon p-1'>
                      <Link
                        to={`/residence/${usersId}/${identificationCode}`}
                      ></Link>
                    </div>
                    <h4>View Residence</h4>
                  </div>
                  <div>
                    <button className='dropdown-btn'>
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>
              <div className='residents-border py-4'>
                <div className='pro-file p-4'>
                  <div className='profile-details'>
                    <div className='text-de_tails'>
                      <strong>Identification Code:</strong>
                      <div>
                        <input
                          type='text'
                          value={formatIdentificationCode(identificationCode)}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />

                    <div className='text-de_tails'>
                      <strong>Title:</strong>
                      <div>
                        <input type='text' value={residence.title} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>First Name:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.firstname}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Last Name:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.lastname}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Other Name:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.othername}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />

                    <div className='text-de_tails'>
                      <strong>Email:</strong>
                      <div>
                        <input type='text' value={residence.email} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Created on:</strong>
                      <div>
                        <input type='text' value={inputDate} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Phone Number:</strong>
                      <div>
                        <input type='text' value={residence.phone} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Home Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.residence_id
                              ? residence.status_id.resident || 'nothing'
                              : 'nothing'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Other Home Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.other_status}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Resident Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.residence_id
                              ? residence.residence_id.category || 'nothing'
                              : 'nothing'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>House Number:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.house_id
                              ? residence.house_id.house_number || 'nothing'
                              : 'No House ID'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='text-de_tails'>
                      <strong>Occupation:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.occupation}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                  <div className='profile-section'>
                    <div className='profile__image'>
                      {residence && residence.avatar ? (
                        <img src={residence.avatar} alt='' />
                      ) : (
                        <p>No avatar available</p>
                      )}
                    </div>
                    <br />
                    <div
                      style={{
                        height: 'auto',
                        margin: '0 auto',
                        maxWidth: 150,
                        width: '100%',
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%',
                        }}
                        value={user_id}
                        log
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </div>
                </div>
                <div className='media_profile'>
                  <div className='all_mediainput'>
                    <div className='med_profile'>
                      <strong>Identification Code:</strong>
                      <div>
                        <input
                          type='text'
                          value={formatIdentificationCode(identificationCode)}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>First Name:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.firstname}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Last Name:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.lastname}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Email:</strong>
                      <div>
                        <input type='text' value={residence.email} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Created on:</strong>
                      <div className='da_te'>
                        <input type='text' value={inputDate} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Phone Number:</strong>
                      <div>
                        <input type='text' value={residence.phone} readOnly />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Resident Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.residence_id
                              ? residence.residence_id.category || 'nothing'
                              : 'nothing'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Home Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.residence_id
                              ? residence.status_id.resident || 'nothing'
                              : 'nothing'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>Other Status:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.other_status}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />
                    <div className='med_profile'>
                      <strong>House Number:</strong>
                      <div>
                        <input
                          type='text'
                          value={
                            residence.house_id
                              ? residence.house_id.house_number || 'nothing'
                              : 'No House ID'
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <br />

                    <div className='med_profile'>
                      <strong>Occupation:</strong>
                      <div>
                        <input
                          type='text'
                          value={residence.occupation}
                          readOnly
                        />
                      </div>
                    </div>
                    <br />

                    <div
                      style={{
                        height: 'auto',
                        margin: '0 auto',
                        maxWidth: 150,
                        width: '100%',
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: 'auto',
                          maxWidth: '40%',
                          width: '100%',
                        }}
                        value={user_id}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </div>

                  <div className='media__profile-section'>
                    <div className='profile__image'>
                      {residence && residence.avatar ? (
                        <img src={residence.avatar} alt='' />
                      ) : (
                        <p>No avatar available</p>
                      )}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
};

export default SingleResidentProfile;
