import React from 'react';
import { useLocation } from 'react-router';

function Notifications() {
  const location = useLocation();
  const { responses } = location.state || { responses: [] };

  return (
    <div>
      <h4>Notifications</h4>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {responses.map((response, index) => (
          <li key={index} style={{ marginBottom: '10px' }}>
            <div 
              style={{ 
                backgroundColor: '#d5e4f8', 
                padding: '10px', 
                borderRadius: '5px', 
                cursor: 'pointer',
                height: '150px'
              }}
            >
              <p style={{ margin: 0, fontWeight: 'bold' }}>{response.response_text}</p>
              <small style={{ display: 'block', marginTop: '5px' }}>
                {new Date(response.response_date).toLocaleString()}
              </small>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications;
