import React, { useEffect, useState } from 'react';
import { BsBarChart } from 'react-icons/bs';
import { RiHome4Fill } from 'react-icons/ri';
import { MdOutlineConstruction } from 'react-icons/md';
import { AiTwotoneMoneyCollect } from 'react-icons/ai';
import { CiSquareQuestion } from 'react-icons/ci';
import { SiEditorconfig } from 'react-icons/si';
import { GoSignIn } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { TbReportSearch } from 'react-icons/tb';
import { MdOutlineEmergency } from 'react-icons/md';
import { FaNetworkWired } from 'react-icons/fa';
import Qrscanner from '../Qrscanner'
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function Compo() {
  const estate_id = localStorage.getItem('estate_id');
  const [estateLogo, setEstateLogo] = useState(null);

  useEffect(() => {
    const fetchEstateLogo = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/estates/logos/${estate_id}`
        );
        setEstateLogo(response.data.avatar);
      } catch (error) {
        console.error('Error fetching estate logo:', error);
      }
    };

    fetchEstateLogo();
  }, [estate_id]);

  return (
    <div dataname="examples">
      <div className='main-panel'>
        <div className='bgg'>
          {estateLogo && (
            <div>
              <img src={estateLogo} alt='Estate Logo' />
            </div>
          )}
        </div>
        <div className='border-line'></div>
        <br />
        <div className='dashboard'>
          <div className='dash_board scrollable'>
            <Link className='line' to='/panel'>
              <div className='dash d-flex text-white gap-3 p-2'>
                <div>
                  <BsBarChart />
                </div>
                <div className='manage mt-1'>
                  <h6>Dashboard</h6>
                </div>
              </div>
            </Link>
            <div className='dash d-flex text-white p-2'>
              <div>
                <RiHome4Fill />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingOne'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseOne'
                      aria-expanded='false'
                      aria-controls='flush-collapseOne'
                    >
                      <div className='manage mt-2'>
                        <h6>Manage Resident</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseOne'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingOne'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/ResidentForm'} className='line'>
                        <p>Create New Residents</p>
                      </Link>
                      <Link to={'/allResidents'} className='line'>
                        <p>View All Residents</p>
                      </Link>
                      <Link to={'/custom'} className='line'>
                        <p>Generate Custom Report</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='dash2 d-flex text-white p-2'>
              <div>
                <AiTwotoneMoneyCollect />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingTwo'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseTwo'
                      aria-expanded='false'
                      aria-controls='flush-collapseTwo'
                    >
                      <div className='manage mt-2'>
                        <h6>Manage Payments</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseTwo'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingTwo'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      {/* <Link to={'/addpayments'} className='line'>
                        <p>All Payments</p>
                      </Link>
                      <Link to={'/paymentsInfo'} className='line'>
                        <p>Sort Payments Info</p>
                      </Link> */}
                      <Link to={'/compliance'} className='line'>
                        <p>View Compliance</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='dash2 d-flex text-white p-2'>
              <div>
                <CiSquareQuestion />
              </div>

              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingFive'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseFive'
                      aria-expanded='false'
                      aria-controls='flush-collapseFive'
                    >
                      <div className='manage mt-2'>
                        <h6>Manage iRequests</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseFive'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingFive'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/pending'} className='line'>
                        <p>Pending iReports</p>
                      </Link>
                      <Link to={'/viewReports'} className='line'>
                        <p>View All iReports</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link className='line' to={`/generate`}>
              <div className='dash2 d-flex text-white gap-3 p-2'>
                <div>
                  <TbReportSearch />
                </div>
                <div className='manage mt-1'>
                  <h6>Generate Reports</h6>
                </div>
              </div>
            </Link>
            <div className='dash d-flex text-white p-2'>
              <div>
                <FaNetworkWired />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingSxx'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseSxx'
                      aria-expanded='false'
                      aria-controls='flush-collapseSxx'
                    >
                      <div className='manage mt-2'>
                        <h6>Manage Employees</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseSxx'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingSxx'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/workers'} className='line'>
                        <p>Add New Worker</p>
                      </Link>
                      <Link to={'/workerslist'} className='line'>
                        <p>View All Workers</p>
                      </Link>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Link className='line' to={`/config`}>
              <div className='dash d-flex text-white gap-3 p-2'>
                <div>
                  <SiEditorconfig />
                </div>
                <div className='manage mt-1'>
                  <h6>Configuration</h6>
                </div>
              </div>
            </Link>

            <div className='dash d-flex text-white p-2'>
              <div>
                <MdOutlineConstruction />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingSx'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseSx'
                      aria-expanded='false'
                      aria-controls='flush-collapseSx'
                    >
                      <div className='manage mt-2'>
                        <h6>Manage Artisan</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseSx'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingSx'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/addartisan'} className='line'>
                        <p>Add New Artisan</p>
                      </Link>
                      <Link to={'/viewall'} className='line'>
                        <p>View All Artisan</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='dash d-flex text-white p-2'>
              <div>
                <GoSignIn />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingOnez'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseOnez'
                      aria-expanded='false'
                      aria-controls='flush-collapseOnez'
                    >
                      <div className='manage mt-2'>
                        <h6>Notifications</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseOnez'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingOnez'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/notify'} className='line'>
                        <p>Send Notifications</p>
                      </Link>
                      <Link to={'/allnotifications'} className='line'>
                        <p>View Notifications</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='dash d-flex text-white p-2'>
              <div>
                <MdOutlineEmergency />
              </div>
              <div
                className='accordion accordion-flush'
                id='accordionFlushExample'
              >
                <div className='accordion-item'>
                  <h5 className='accordion-header' id='flush-headingSv'>
                    <button
                      className='a-btn accordion-button collapsed bg-black text-white'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#flush-collapseSv'
                      aria-expanded='false'
                      aria-controls='flush-collapseSv'
                    >
                      <div className='manage mt-2'>
                        <h6>Emergency</h6>
                      </div>
                    </button>
                  </h5>
                  <div
                    id='flush-collapseSv'
                    class='accordion-collapse collapse'
                    aria-labelledby='flush-headingSv'
                    data-bs-parent='#accordionFlushExample'
                  >
                    <div className='accordion-body text-white bg-black'>
                      <Link to={'/emergency'} className='line'>
                        <p>New Emergency </p>
                      </Link>
                      <Link to={'/allemergency'} className='line'>
                        <p>View All Emergency</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          {/* <Link className='line' to='/notify'>
            <div className='dash d-flex text-white gap-3 p-2'>
              <div>
                <GoSignIn />
              </div>
              <div className='manage mt-1'>
                <h6>Notifications</h6>
              </div>
            </div>
          </Link> */}

          {/* <Link className='line' to='#'>
            <div className='dash d-flex text-white gap-3 p-2'>
              <div>
                <GoSignIn />
              </div>
              <div className='manage mt-1'>
                <h6>Help</h6>
              </div>
            </div>
          </Link> */}
          <div>
            <Qrscanner />
          </div>

          {/* <div className='drk'>
            <Dark />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Compo;
