import React, { useState, useEffect } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import { Link } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';
import { GiNotebook } from 'react-icons/gi';
import { SiGooglephotos } from 'react-icons/si';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { MdHistoryEdu } from 'react-icons/md';

function Dashboard() {
  const [notifications, setNotifications] = useState([]);
  const estate_id = localStorage.getItem('estate_id');
  const is_admin = localStorage.getItem('role');
  const is_logged_in = localStorage.getItem('user_id');
  let fullname = localStorage.getItem('firstname');
  const user = is_admin === 'USER';
  const isLoggedIn = !!is_logged_in;


  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      return 'Good Morning';
    } else if (hour >= 12 && hour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };


 useEffect(() => {
   const fetchNotifications = async () => {
     try {
       const response = await axios.get(
         `${BASE_URL}/notify/recent/estate/${estate_id}`
       );
       console.log('API Response:', response.data); 
       setNotifications(response.data); 
     } catch (error) {
       console.error('Error fetching notifications:', error);
     }
   };

   if (estate_id) {
     fetchNotifications();
   }
 }, [estate_id]);


 useEffect(() => {
   if (isLoggedIn && fullname) {
     const user_id = localStorage.getItem('user_id');

     // fetchUnreadNotificationCount(user_id);
   }
 }, [isLoggedIn, fullname]);


  return (
    <div>
      {isLoggedIn && user ? (
        <div className='dash_board d-flex'>
          <div className='users'>
            <Left />
          </div>

          <div className='users_'>
            <Navigations />
            <br />

            <div className='dashboard-border'>
              <h5>
                {getGreeting()}, {fullname}
              </h5>
              {notifications.map((notification) => (
                <React.Fragment key={notification._id}>
                  <h4>{notification.header}</h4>
                  <br />
                  <div className='dashboard--border'>
                    <div
                      className='dash_text'
                      style={{ padding: '10px', maxWidth: '100%' }}
                    >
                      <h3>{notification.sub_header}</h3>
                      <span>{notification.date}</span>
                      <h5>{notification.info_head}</h5>
                      <div className='notification-info'>
                        <p>{notification.info}</p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
            <div className='users-dash-board'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 mb-4'>
                  <Link to='/helpline' className='line'>
                    <div className='artisan--cardone'>
                      <div className='artisan--flex d-flex'>
                        <div className='card-content'>
                          <p>Security & Emergency Contact</p>
                          <span>Click here to contact security team</span>
                        </div>

                        <div
                          className='artisan-iconone'
                          style={{
                            backgroundColor: 'blue',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                        >
                          <span className='artisan-iconz'>
                            <GoSearch />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mb-4'>
                  <Link to='/artisans' className='line'>
                    <div className='artisan--cardtwo'>
                      <div className='artisan--flex d-flex'>
                        <div className='card-content'>
                          <p>Registered Artisans Directory</p>
                          <span>Get verified Artisans here</span>
                        </div>

                        <div
                          className='artisan-iconone'
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                        >
                          <span className='artisan-iconztwo'>
                            <GiNotebook />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mb-4'>
                  <Link to='/ireport' className='line'>
                    <div className='artisan--cardthree'>
                      <div className='artisan--flex d-flex'>
                        <div className='card-content'>
                          <p>Submit Detailed iReports Plus Photos</p>
                          <span>See Something, Say Something</span>
                        </div>

                        <div
                          className='artisan-iconone'
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                        >
                          <span className='artisan-iconztwo'>
                            <SiGooglephotos />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mb-4'>
                  <Link to='/history' className='line'>
                    <div className='artisan--cardfour'>
                      <div className='artisan--flex d-flex'>
                        <div className='card-content'>
                          <p>iReports History</p>
                          <span>Click here to view ireports history</span>
                        </div>

                        <div
                          className='artisan-iconone'
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                        >
                          <span className='artisan-iconztwo'>
                            <MdHistoryEdu />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mb-4'>
                  <Link to='/archives' className='line'>
                    <div className='artisan--cardfv'>
                      <div className='artisan--flex d-flex'>
                        <div className='card-content'>
                          <p>View All Notifications</p>
                          <span>
                            Click here to see all previous notifications
                          </span>
                        </div>

                        <div
                          className='artisan-iconone'
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                        >
                          <span className='artisan-iconztwo'>
                            <IoIosNotificationsOutline />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}

      <br />
    </div>
  );
}

export default Dashboard;
